import React from "react";


function VHPolaroid({
  videoRef,
  takePicture,
  showCountdown,
  countdown,
  showImage,
  latestImage,
  isMobileDevice,
  buttonStyle,
  pageName, // Added pageName as a prop
  consentVideoMicrophone,
  handleConsentVideoMicrophone,
}) {
  return (
    <div className={`${pageName}-polaroid-container`}>
      {showCountdown && (
        <div className={`${pageName}-countdown ${isMobileDevice ? `${pageName}-countdown-mobile` : ""}`}>
          {countdown}
        </div>
      )}
      {showImage ? (
        <img src={latestImage} className={`${pageName}-picture`} alt="Latest" />
      ) : (
        consentVideoMicrophone && (
          <div className={`${pageName}-video-wrapper-container`}>
            <video className={`${pageName}-videoCam`} ref={videoRef} autoPlay muted playsInline />
          </div>
        )
      )}

      {!showImage && (
        consentVideoMicrophone ? (
            <button className={`${buttonStyle}`} onClick={takePicture}>
            </button>
        ) : (
          <div className={`${pageName}-consent-video-microphone-container`}>
            <button
              className={`${pageName}-consent-video-microphone`}
              onClick={() => handleConsentVideoMicrophone(true)}
            >
              To grant video camera access, click here
            </button>
          </div>
        )
      )}
    </div>
  );
}


export default VHPolaroid;

