import "./MaxHeat.css";
import React, { useEffect, useState, useRef } from "react";
import { TalkingHead } from "../utils/Talkinghead";
import axios from "axios";
import { base64ToArrayBuffer } from "../utils/base64toArraybuffer";
import {
  MaxHeatGreetingEN,
  MaxHeatEndingEN,
  MaxHeatGreetingNL,
  MaxHeatEndingNL,
} from "./MaxHeatStandardSpeech";
import mach8Logo from "../assets/images/powered-by-m8.png";
import { getRoastQuotesAPI } from "../api/GetRoastQuotesAPI";
import ReactMarkdown from "react-markdown";

// Import the refactored components with 'VH' prefix
import VHPolaroid from "../Components/VHPolaroid";
import HeatVHIntroModal from "./HeatVHIntroModal";
import HeatVHFinishedPage from "./HeatVHFinishedPage";

// Global variables
let head = null;
let loaded = false;
let isWaitingForDescriptionResult = false;
let isAvatarThinking = false;

function MaxHeat() {
  // Refs
  const videoRef = useRef();
  const avatarRef = useRef(null);
  const loadingRef = useRef(null);

  // State variables
  const [pauseButtonText, setPauseButtonText] = useState("Pause");
  const [consentVideoMicrophone, setConsentVideoMicrophone] = useState(false);
  const [lockStartButton, setLockStartButton] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [countdown, setCountdown] = useState(3);
  const [showCountdown, setShowCountdown] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [latestImage, setLatestImage] = useState(null);
  const [language, setLanguage] = useState("en");
  const [userDescription, setUserDescription] = useState("");
  const [isComplimenting, setIsComplimenting] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [MaxHeatSpeech, setMaxHeatSpeech] = useState({});

  // New state variables for finished page
  const [finishedSpeaking, setFinishedSpeaking] = useState(false);
  const [roastText, setRoastText] = useState("");
  const [roastQuotes, setRoastQuotes] = useState("");
  const [email, setEmail] = useState("");

  // useEffect hooks
  useEffect(() => {
    loadAvatar();
    checkIfMobile();


    // Extract parameters from URL
    const params = new URLSearchParams(window.location.search);
    const languageParam = params.get("language");
    const contextParam = params.get("context");

    if (!userDescription && contextParam) {
      // Decode the context parameter from Base64
      const decodedDescription = base64DecodeUnicode(contextParam);
      setUserDescription(decodedDescription);
      setLanguage(languageParam || "en");
      // Hide the intro modal
      setShowPopup(false);

      // IMPORTANT: Ensure consentVideoMicrophone remains false
      setConsentVideoMicrophone(false);
    }
  }, []);

  // Encoding for params
  function base64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
        match,
        p1
      ) {
        return String.fromCharCode('0x' + p1);
      })
    );
  }

  function base64DecodeUnicode(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  useEffect(() => {
    if (!loaded) {
      const timer = setTimeout(() => {
        setLockStartButton(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
    if (consentVideoMicrophone) {
      startVideo();
      setShowPopup(false);
    }
  }, [consentVideoMicrophone]);

  useEffect(() => {
    if (!finishedSpeaking) {
      loadAvatar();
    }
  }, [finishedSpeaking]);
  // Functions

  // Starts the video feed
  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true;
        videoRef.current.play();
      })
      .catch((err) => console.error("Error accessing webcam: ", err));
  };


  // Stops the video feed
  const stopVideo = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  // Loads the avatar on the screen
  const loadAvatar = async () => {
    if (loaded) return;
    loaded = true;
    const nodeAvatar = avatarRef.current;

    // Determine lipsync module based on language
    const lipsyncModule = language === "nl" ? "nl" : "en";

    head = new TalkingHead(nodeAvatar, {
      ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
      ttsApikey: process.env.REACT_APP_GOOGLE_TTS_API_KEY,
      lipsyncModules: [lipsyncModule],
      cameraView: "upper",
    });


    // Load and show the avatar
    const startButton = document.querySelector('.MaxHeat-button');
    const nodeLoading = loadingRef.current;
    startButton.classList.add("disable");
    try {
      nodeLoading.textContent = "Loading...";
      let config = {};
      if (language === "en") {
        config = {
          url: "https://models.readyplayer.me/66e80709256d689574fde12a.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png",
          body: "M",
          ttsVoice: "en-GB-Standard-B",
          avatarMood: "neutral",
          ttsLang: "en-GB",
          lipsyncLang: "en",
        };
      } else if (language === "nl") {
        config = {
          url: "https://models.readyplayer.me/66e80709256d689574fde12a.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png",
          body: "M",
          ttsVoice: "nl-NL-Standard-C",
          avatarMood: "neutral",
          ttsLang: "nl-NL",
          lipsyncLang: "nl",
        };
      }
      await head.showAvatar(config, (ev) => {
        if (ev.lengthComputable) {
          let val = Math.min(1000, Math.round((ev.loaded / ev.total) * 100));
          nodeLoading.textContent = "Loading " + val + "%";
        }
      });
      nodeLoading.style.display = "none";
      startButton.classList.remove("disable");
    } catch (error) {
      nodeLoading.textContent = error.toString();
    }
  };


  // Describes the image of the user everytime the recording is started. This way a description is ready for the conversation.
  const DescribeImage = async () => {
    if (isWaitingForDescriptionResult) {
      return;
    }

    isWaitingForDescriptionResult = true;
    console.log("Describing the image");
    const video = videoRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL("image/jpg");
    setLatestImage(image);

    let messages = [];
    if (language === "en") {
      messages = [
        {
          role: "system",
          content: `Describe the person in the image. Do not describe anything about their surroundings. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response"`,
        },
        {
          role: "user",
          content: [{ type: "image_url", image_url: { url: image } }],
        },
      ];
    } else if (language === "nl") {
      messages = [
        {
          role: "system",
          content: `Beschrijf de persoon in de afbeelding. Beschrijf niks anders buiten de persoon. Beschrijf de omgeving niet. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response". Write in Dutch, maak de beschrijving in het Nederlands`,
        },
        {
          role: "user",
          content: [{ type: "image_url", image_url: { url: image } }],
        },
      ];
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/VHResponse`,
      { messages: messages }
    );
    console.log("Description response: ", response);
    await roastUser(response.data.response);
  };


  // Make a response based on the image of the user and the transcription of the user
  async function roastUser(description) {
    console.log("Getting response to answer the user");
    try {
      let messages = [];

      if (!isComplimenting) {
        if (language === "en") {
          messages = [
            {
              role: "system",
              content:
                "Your task is to roast the user based on the description that is given. You are allowed to be aggressive.",
            },
            {
              role: "user",
              content: `The user is: ${userDescription}. And is described as follows: ${description}.`,
            },
          ];
        } else if (language === "nl") {
          messages = [
            {
              role: "system",
              content:
                "Jouw taak is om de gebruiker te beledigen op basis van de beschrijving die is gegeven. Het is toegestaan om aggresief te zijn. Schrijf in het Nederlands, write in Dutch.",
            },
            {
              role: "user",
              content: `De gebruiker is: ${userDescription}. En wordt als volgt beschreven: ${description}.`,
            },
          ];
        }
      } else {
        if (language === "en") {
          messages = [
            {
              role: "system",
              content:
                "Your task is to compliment the user based on the description that is given.",
            },
            {
              role: "user",
              content: `The user is: ${userDescription}. And is described as follows: ${description}.`,
            },
          ];
        } else if (language === "nl") {
          messages = [
            {
              role: "system",
              content:
                "Jouw taak is om de gebruiker een compliment te geven op basis van een gegeven beschrijving. Schrijf in het Nederlands, write in Dutch",
            },
            {
              role: "user",
              content: `De gebruiker is: ${userDescription}. En wordt als volgt beschreven: ${description}.`,
            },
          ];
        }
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/VHResponseGroq`,
        { messages: messages }
      );
      console.log("Roast response: ", response);

      // Store the roast text in state
      const currentRoastText = response.data.response;
      setRoastText(currentRoastText);

      console.log("Getting speech response");
      // Generate audio and visemes
      const speechResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/VHSpeech`,
        {
          text: response.data.response,
          voiceId: "VR6AewLTigWG4xSOukaG",
        }
      );

      // Convert base64 to ArrayBuffer
      const arrayBuffer = base64ToArrayBuffer(
        speechResponse.data.response.audio
      );

      // Create an AudioContext instance and decode the ArrayBuffer into an AudioBuffer
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Create the audio object expected by playAudio
      const audioObj = {
        audio: audioBuffer,
        words: speechResponse.data.response.alignment.words,
        wtimes: speechResponse.data.response.alignment.wtimes,
        wdurations: speechResponse.data.response.alignment.wdurations,
      };

      console.log("Playing audio");
      head.speakAudio(audioObj);

      isAvatarThinking = false;
      isWaitingForDescriptionResult = false;
      isRoasting();

      const quotes = await getRoastQuotes(currentRoastText);
      setRoastQuotes(quotes);
    } catch (error) {
      console.error(error);
      isAvatarThinking = false;
      isWaitingForDescriptionResult = false;
    }
  }


  async function getRoastQuotes(roastContent) {
    const roastQuotes = await getRoastQuotesAPI(roastContent);
    console.log('quotes:' + roastQuotes.quotes);
    const quotesArray = roastQuotes.quotes.split(/\n/);
    return quotesArray[0].trim();
  }

  function pauseButton() {
    if (isAvatarThinking) {
      head.start();
      setPauseButtonText("Pause");
    } else {
      head.stop();
      setPauseButtonText("Resume");
    }
    isAvatarThinking = !isAvatarThinking;
  }

  function handleModalButton() {
    const popupElement = document.querySelector('.MaxHeat-page-popup');
    const avatarElement = document.querySelector('.avatar.MaxHeat');
  
    if (popupElement) {
      popupElement.classList.add('slide-up');
  
      setTimeout(() => {
        setConsentVideoMicrophone(true);
  
        // Base64 encode the userDescription
        const encodedDescription = base64EncodeUnicode(userDescription);
  
        // Update the URL with parameters
        const params = new URLSearchParams();
        params.set("language", language);
        params.set("context", encodedDescription);
  
        const newURL =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?" +
          params.toString();
        window.history.replaceState({ path: newURL }, "", newURL);
  
        // Call prepSpeech after ensuring head is initialized and audio context is resumed
        prepSpeech();
        avatarElement.classList.add('show');
      }, 600);
    }
  }

  function handleFinish() {
    window.location = '/';
  }

  async function takePicture() {
    const picButton = document.querySelector('.MaxHeat-picture-button');
    head.speakAudio(MaxHeatSpeech.greeting);

    setShowCountdown(true); // Show the countdown overlay
    setCountdown(3);
    setTimeout(() => {
      setCountdown(2);
    }, 1000);
    setTimeout(() => {
      setCountdown(1);
    }, 2000);
    setTimeout(() => {
      setShowCountdown(false);
      setCountdown(3);
      setShowImage(true);
      DescribeImage();
      stopVideo(); // Turn off the user's camera after taking the picture
    }, 3000);
  }


  async function isRoasting() {
    if (!head.isSpeaking && pauseButtonText === "Pause") {
      head.speakAudio(MaxHeatSpeech.ending);
      setTimeout(() => {
        setShowImage(false);
        // Set finishedSpeaking to true after ending speech
        setFinishedSpeaking(true);
      }, 3000);
    } else {
      setTimeout(() => {
        isRoasting();
      }, 1500);
    }
  }

  async function prepSpeech() {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    if (language === "en") {
      const arrayBufferGreeting = base64ToArrayBuffer(MaxHeatGreetingEN.audio);
      MaxHeatGreetingEN.audio = await audioContext.decodeAudioData(
        arrayBufferGreeting
      );
      const arrayBufferEnding = base64ToArrayBuffer(MaxHeatEndingEN.audio);
      MaxHeatEndingEN.audio = await audioContext.decodeAudioData(
        arrayBufferEnding
      );
      MaxHeatSpeech.greeting = MaxHeatGreetingEN;
      MaxHeatSpeech.ending = MaxHeatEndingEN;
    } else if (language === "nl") {
      const arrayBufferGreeting = base64ToArrayBuffer(MaxHeatGreetingNL.audio);
      MaxHeatGreetingNL.audio = await audioContext.decodeAudioData(
        arrayBufferGreeting
      );
      const arrayBufferEnding = base64ToArrayBuffer(MaxHeatEndingNL.audio);
      MaxHeatEndingNL.audio = await audioContext.decodeAudioData(
        arrayBufferEnding
      );
      MaxHeatSpeech.greeting = MaxHeatGreetingNL;
      MaxHeatSpeech.ending = MaxHeatEndingNL;
    }
  }


  const handleLanguageSelect = (countryCode) => {
    if (countryCode === "US" || countryCode === "GB") {
      setLanguage("en");
    } else if (countryCode === "NL") {
      setLanguage("nl");
    }
    // Reload speech when language changes
    // prepSpeech();
  };

  function selectLanguage(lang) {
    setLanguage(lang);
  }

  // Selects the attitude (roast or compliment)
  const selectAttitude = (attitude) => {
    setIsComplimenting(attitude);
  };

  // Handles input change for user description
  const handleInputChange = (event) => {
    setUserDescription(event.target.value);
  };

  // Handles email input change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Handles mailing the roast to the user
  const handleMailRoast = async () => {
    // Implement email functionality here
    // For now, resetting the state to start over
    setFinishedSpeaking(false);
    startVideo();

    // Clear URL parameters
    window.history.replaceState({}, document.title, window.location.pathname);
  };


  // Checks if the device is mobile
  const checkIfMobile = () => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 1024) {
      setIsMobileDevice(false);
    }
  };

  // Adjust classNames based on showPopup and isMobileDevice
  const avatarClass = `${isMobileDevice ? "MaxHeat-mobile" : "MaxHeat"} ${
    showPopup ? "MaxHeat-popup" : "MaxHeat"
  }`;

  function handleConsentVideoMicrophone(consent) {
    setConsentVideoMicrophone(consent);
    prepSpeech();
  }

  return (
    <div>
      <div className={`App Page-MaxHeat ${showPopup ? "MaxHeat-popup-background" : "MaxHeat-background"}`}>
        <div className="max-container">
          {/* Content */}
          {!finishedSpeaking ? (
            <>
              <div className="mach8-logo heat">
                <img src={mach8Logo} alt="Mach8 Logo" />
              </div>
              <div className="MaxHeat-avatar-background">
                {/* Polaroid Container */}
                {!showPopup && (
                  <VHPolaroid
                    videoRef={videoRef}
                    takePicture={takePicture}
                    showCountdown={showCountdown}
                    countdown={countdown}
                    showImage={showImage}
                    latestImage={latestImage}
                    isMobileDevice={isMobileDevice}
                    buttonStyle={"MaxHeat-picture-button MaxHeat-button"}
                    pageName={"MaxHeat"}
                    consentVideoMicrophone={consentVideoMicrophone}
                    handleConsentVideoMicrophone={handleConsentVideoMicrophone}
                  />
                )}
              </div>

              {/* Intro Modal */}
              {showPopup && (
                <HeatVHIntroModal
                  isMobileDevice={isMobileDevice}
                  language={language}
                  userDescription={userDescription}
                  isComplimenting={isComplimenting}
                  handleInputChange={handleInputChange}
                  handleLanguageSelect={handleLanguageSelect}
                  selectLanguage={selectLanguage}
                  selectAttitude={selectAttitude}
                  handleModalButton={handleModalButton}
                  buttonStyle={"MaxHeat-button"}
                  buttonText={language === "en" ? "Start roasting!" : "Start met roasten!"}
                  contextText={language === "en" ? "Tell me some fun facts about yourself:" : "Vul een paar leuke feitjes over jezelf in:"}
                  pageName={"MaxHeat"}
                />
              )}
              <div className="MaxHeat-avatar-container">
                <div className="avatar MaxHeat" ref={avatarRef}></div>
                <div ref={loadingRef} id="loading" className="text-black"></div>
              </div>
            </>
          ) : (
            <>
              <div className="mach8-logo heat">
                <img src={mach8Logo} alt="Mach8 Logo" />
              </div>
              <HeatVHFinishedPage
                latestImage={latestImage}
                quotes={roastQuotes}
                email={email}
                handleEmailChange={handleEmailChange}
                handleMail={handleMailRoast}
                handleFinish={handleFinish}
                buttonStyle={"MaxHeat-email-button MaxHeat-button"}
                buttonText={language === "en" ? "Mail me my roast" : "Mail me my roast"}
                pageName={"MaxHeat"}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}


export default MaxHeat;