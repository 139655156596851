import React from "react";
import ReactFlagsSelect from "react-flags-select";

function VHIntroModal({
  isMobileDevice,
  language,
  userDescription,
  isComplimenting,
  handleInputChange,
  handleLanguageSelect,
  selectLanguage,
  selectAttitude,
  handleModalButton,
  buttonStyle,
  buttonText,
  titleText,
  infoText,
  contextText,
  inputPlaceholder,
  pageName,
}) {
  return (
    <>
      {isMobileDevice ? (
        <div className={`${pageName}-intro-modal`}>
          <div className={`${pageName}-intro-modal__text-mobile`}>
            <div>
              <h3>{titleText}</h3>
              <p className={`${pageName}-main-text`}>Select a language:</p>
              {language === "en" ? (
                <button className={`${pageName}-selected-button`}>English</button>
              ) : (
                <button className={`${pageName}-button`} onClick={() => selectLanguage("en")}>
                  English
                </button>
              )}
              {language === "nl" ? (
                <button className={`${pageName}-selected-button ${pageName}-nederlands-mobile`}>
                  Nederlands
                </button>
              ) : (
                <button
                  className={`${pageName}-button ${pageName}-nederlands-mobile`}
                  onClick={() => selectLanguage("nl")}
                >
                  Nederlands
                </button>
              )}
              <p className={`${pageName}-main-text`}>Pick Max's attitude:</p>
              {!isComplimenting ? (
                <button className={`${pageName}-selected-button`}>Roasting</button>
              ) : (
                <button className={`${pageName}-button`} onClick={() => selectAttitude(false)}>
                  Roasting
                </button>
              )}
              {isComplimenting ? (
                <button className={`${pageName}-selected-button ${pageName}-complimenting ${pageName}-nederlands-mobile`}>
                  Complimenting
                </button>
              ) : (
                <button
                  className={`${pageName}-button ${pageName}-complimenting-mobile`}
                  onClick={() => selectAttitude(true)}
                >
                  Complimenting
                </button>
              )}

              <p className={`${pageName}-main-text`}>{contextText}</p>
              <input
                type="text"
                value={userDescription}
                onChange={handleInputChange}
                className={`${pageName}-input-field-mobile`}
                placeholder="Enter context..."
              />
            </div>
            <button className={buttonStyle} style={{ marginTop: "25px" }} onClick={handleModalButton}>
              Start
            </button>
          </div>
        </div>
      ) : (
        <div className={`${pageName}-page-popup flex flex-row`}>
          <div className={`${pageName}-intro-modal`}>
            <div className={`${pageName}-intro-modal__text`}>
              {titleText}
              <div className={`${pageName}-intro-modal__info-wrapper`}>
                <div className={`${pageName}-intro-modal__input-wrapper`}>
                  <p className={`${pageName}-intro-modal__text-info`}>
                    {infoText}
                  </p>
                  <div className={`${pageName}-intro-modal__text-input-group`}>
                    <p className={`${pageName}-main-text`}>{contextText}</p>
                    <div className={`${pageName}-intro-modal__field-group`}>
                      <input
                        type="text"
                        value={userDescription}
                        onChange={handleInputChange}
                        className={`${pageName}-input-field`}
                        placeholder={inputPlaceholder? inputPlaceholder: "Type here..."} 
                      />
                      { handleLanguageSelect &&
                        <div>
                          { pageName != "MaxHeat" ? ( <p className={`${pageName}-language-text mb-2`}>Select a language</p> ) : null }
                          <ReactFlagsSelect
                            selected={language === "nl" ? "NL" : "GB"}
                            onSelect={handleLanguageSelect}
                            countries={["NL", "GB"]}
                            customLabels={{ NL: "Nederlands",  GB: "English" }}
                            className={`${pageName}-language-dropdown`}
                            selectedSize={24}
                            optionsSize={24}
                            showOptionLabel={pageName != "MaxHeat"}
                            fullWidth={true}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <button className={buttonStyle} onClick={handleModalButton}>
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VHIntroModal;