import React from "react";
import ReactFlagsSelect from "react-flags-select";
import mach8BigLogo from "../assets/images/maxheat-big-logo.png";

function HeatVHIntroModal({
  isMobileDevice,
  language,
  userDescription,
  isComplimenting,
  handleInputChange,
  handleLanguageSelect,
  selectLanguage,
  selectAttitude,
  handleModalButton,
  buttonStyle,
  buttonText,
  titleText,
  infoText,
  contextText,
  inputPlaceholder,
  pageName,
}) {
  return (
    <>
      <div className={`${pageName}-page-popup flex flex-row`}>
        <div className={`${pageName}-intro-modal`}>
          <img src={mach8BigLogo} alt="Mach8 Big Logo" />
          <div className={`${pageName}-intro-modal__text`}>
            {titleText}
            <div className={`${pageName}-intro-modal__info-wrapper`}>
              <div className={`${pageName}-intro-modal__input-wrapper`}>
                <p className={`${pageName}-intro-modal__text-info`}>
                  {infoText}
                </p>
                <div className={`${pageName}-intro-modal__text-input-group`}>
                  <p className={`${pageName}-main-text`}>{contextText}</p>
                  <div className={`${pageName}-intro-modal__field-group`}>
                    <input
                      type="text"
                      value={userDescription}
                      onChange={handleInputChange}
                      className={`${pageName}-input-field`}
                      placeholder={inputPlaceholder? inputPlaceholder: "Type here..."} 
                    />
                    { handleLanguageSelect &&
                      <div>
                        { pageName != "MaxHeat" ? ( <p className={`${pageName}-language-text mb-2`}>Select a language</p> ) : null }
                        <ReactFlagsSelect
                          selected={language === "nl" ? "NL" : "GB"}
                          onSelect={handleLanguageSelect}
                          countries={["NL", "GB"]}
                          customLabels={{ NL: "Nederlands",  GB: "English" }}
                          className={`${pageName}-language-dropdown`}
                          selectedSize={24}
                          optionsSize={24}
                          showOptionLabel={pageName != "MaxHeat"}
                          fullWidth={true}
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
              <button className={buttonStyle} onClick={handleModalButton}>
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeatVHIntroModal;