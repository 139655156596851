import React from 'react';
import ReactMarkdown from 'react-markdown';
import maxPin from "../assets/images/max-pin.png";

function HeatVHFinishedPage({
  latestImage,
  quotes,
  email,
  handleEmailChange,
  handleMail,
  handleFinish,
  buttonStyle,
  buttonText,
  pageName, // Added pageName as a prop
}) {
  return (
    <div className={`${pageName}-Finished-container-wrapper`}>
      <img className="max-pin" src={maxPin} alt="Max Pin" />
      <div className={`${pageName}-Finished-container`}>
        <img src={latestImage} alt="Your Picture" className={`${pageName}-picture-finished`} />
        <ReactMarkdown className={`${pageName}-roast-text`}>{quotes}</ReactMarkdown>
        <p className={`send-off`}>- Max 🥰</p>
      </div>
      <button className={buttonStyle} onClick={handleFinish}>
        Nieuwe roast
      </button>
    </div>
  );
}

export default HeatVHFinishedPage;